<template>
    <div>
        <CRow>
            <CCol sm="12">
                <CTableWrapper
                :items="data"
                :fields="fields"
                hover
                :striped="true"
                :border="true"
                small
                fixed
                caption="Lista de Proveedores Gasto"
                icon="fas fa-dolly"
                btn_name="Proveedor Gasto"
                @show_modal="mtd_open_modal"
                @action_edit="mtd_show"
                @action_delete="mtd_delete"
                :size="'lg'"
                :button_new="true"
                :actions="true"
                :withActions="'6%'"
                :buttonEdit="true"
                :buttonDelete="true"
                :myButtons="myButtons"
                />
            </CCol>
        </CRow>

        <CRow class="text-center">
            <CCol lg="6" md="6" sm="12" xs="12"
                ><CBadge color="success"
                ><h4>
                    <strong> Proveedores Gastos Activos : {{ cp_count_active }}</strong>
                </h4>
                </CBadge>
            </CCol>
            <CCol lg="6" md="6" sm="12" xs="12"
                ><CBadge color="secondary">
                <h4>
                    <strong>
                    Proveedores Gastos Inactivos : {{ cp_count_inactive }}
                    </strong>
                </h4>
                </CBadge>
            </CCol>
        </CRow>

        <!-- modal -->
        <CModalForm
            :size="'xl'"
            :title="modal.title"
            :button="cp_button"
            :show.sync="modal.modal_form"
            @mtd_action="mtd_action"
        >
        <CRow>
            <CCol lg="3" md="3" sm="12" xs="12">
                <CInput
                    label="Documento"
                    type="number"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    maxlength = "11"
                    placeholder="Digite documento"
                    v-model="supplier_expense.document"
                    v-on:keyup.enter="mtd_search_document"
                    :disabled="modal.action == 'store'?false:true "
                />
            </CCol>
            <CCol lg="5" md="5" sm="12" xs="12">
                <CInput
                    label="Nombres"
                    @keyup="upper($event)"
                    placeholder="Digite nombre de proveedor Gasto"
                    v-model="supplier_expense.name"
                    :disabled="boo_novo"
                />
            </CCol>
            <CCol lg="4" md="4" sm="12" xs="12">
                <CInput
                    label="Negocio"
                    @keyup="upper($event)"
                    placeholder="Nombre del Negocio"
                    v-model="supplier_expense.bussines_name"
                />
            </CCol>
            <CCol lg="3" md="3" sm="12" xs="12">
                <cSelectForm
                    label="Seleccione un pais"
                    :options="data_countries"
                    placeholder="Escoja un pais"
                    :value.sync="supplier_expense.country_id"
                    @change="mtd_select_country"
                />
            </CCol>
            <CCol lg="3" md="3" sm="12" xs="12">
                <cSelectForm
                    label="Seleccione una region"
                    :options="data_regions"
                    placeholder="Escoja una region"
                    :value.sync="supplier_expense.region_id"
                    @change="mtd_select_region"
                    :disabled="boo_country"
                />
            </CCol>
            <CCol lg="3" md="3" sm="12" xs="12">
                <cSelectForm
                    label="Seleccione una provincia"
                    :options="data_province_filter"
                    placeholder="Escoja una provincia"
                    :value.sync="supplier_expense.province_id"
                    @change="mtd_select_province"
                    :disabled="boo_country"
                />
            </CCol>
            <CCol lg="3" md="3" sm="12" xs="12">
                <cSelectForm
                    label="Seleccione un distrito"
                    :options="data_disctrict_filter"
                    placeholder="Escoja un ditrito"
                    :value.sync="supplier_expense.district_id"
                    :disabled="boo_country"
                />
            </CCol>
            <CCol lg="3" md="3" sm="12" xs="12">
                <CInput
                    label="Celular/telefono"
                    placeholder="Digite celular/telefono"
                    v-model="supplier_expense.phone"
                />
            </CCol>
            <CCol lg="3" md="3" sm="12" xs="12">
                <CInput
                    label="Email"
                    type="email"
                    placeholder="Digite Email"
                    v-model="supplier_expense.email"
                />
            </CCol>
            <CCol lg="3" md="3" sm="12" xs="12">
                <CInput
                    label="Dirección"
                    placeholder="Digite dirección"
                    v-model="supplier_expense.address"
                />
            </CCol>
            <CCol lg="3" md="3" sm="12" xs="12">
                <cSelectForm
                    label="Seleccione un estado"
                    :options="data_state"
                    placeholder="Escoja un estado"
                    :value.sync="supplier_expense.state"
                />
            </CCol>
        </CRow>
        </CModalForm>

        <!-- modal delete -->
        <cModalDelete
        title="Borrar Proveedor Gasto"
        :boo_modal="modal_delete.boo"
        :item="modal_delete.item"
        @close_delete="close_delete"
        @mtd_commit="mtd_commit"
        ></cModalDelete>
    </div>
</template>

<script>
const fields = [
  { key: "Id", _style: "width:5%" },
  { key: "document", label: "Documento", _style: "width:20%;" },
  { key: "name", label: "Nombre", _style: "width:40%;" },
  { key: "status", label: "Estado", _style: "width:10%;" },
];

import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import cSelectForm from "../../components/shared/inputs/cSelect.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

export default {
    components: {CTableWrapper, CModalForm, cModalDelete, cSelectForm},
    data() {
        return {
            prefix: "supplier_expenses",
            supplier_expense: {
                id: "",
                document: "",
                name: "",
                bussines_name: "",
                phone: "",
                email: "",
                address: "",
                country_id:"",
                region_id:"",
                province_id: "",
                district_id: "",
                state: "",
            },
            boo_novo: true,
            regions_id: "",
            provinces_id: "",
            districts_id: "",
            fields,
            data: [],
            data_countries:[],
            boo_country:true,
            data_regions:[],
            data_provinces: [],
            data_province_filter:[],
            data_disctrict: [],
            data_disctrict_filter: [],
            data_state: [
                { value: 1, label: "Activo" },
                { value: 2, label: "Inactivo" },
            ],
            myButtons: [],
            modal: {
                action: "",
                title: "",
                modal_form: false,
            },
            modal_delete: {
                boo: false,
                item: [],
            },
        }
    },
    computed: {
        cp_count_active: function(){
            let cc=0;
            this.data.forEach(element => {
                if (element.status =='Activo') {
                cc += 1;
                }
            });
            return cc;
        },
        cp_count_inactive: function(){
            let cc=0;
            this.data.forEach(element => {
                if (element.status !='Activo') {
                cc += 1;
                }
            });
            return cc;
        },
        cp_button: function () {
        },
    },
    created() {
        this.mtd_getdata();
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtd_getdata: function () {
            this.get({
                url: this.$store.getters.get__url + "/" + this.prefix,
                token: this.$store.getters.get__token,
            })
            .then((response) => {
            this.data = response.data.map((item, order) => {
                return { ...item, order };
            });
            this.data_countries = response.data_countries;
            this.data_regions = response.data_regions;
            this.data_provinces = response.provinces;
            this.data_disctrict = response.data_disctrict;
            })
            .catch((errors) => { });
        },
        upper: function (e) {	
             e.target.value = e.target.value.toUpperCase();
        },
        mtd_open_modal: function(boo, action) {
            this.modal.modal_form = boo;
            this.modal.action = action;

            action == "store"
                ? (this.modal.title = "Nuevo proveedor Gasto")
                : (this.modal.title = "Editar proveedor Gasto");
            action == "store"
                ? (this.supplier_expense = {
                    id: "",
                    document: "",
                    name: "",
                    bussines_name: "",
                    phone: "",
                    email: "",
                    address: "",
                    country_id:"",
                    region_id:"",
                    province_id: "",
                    district_id: "",
                    state: "",
                })
                : (this.supplier_expense = this.supplier_expense);
            if (action=='update') {
                if (this.supplier_expense.country_id==173) {
                    this.mtd_select_country();
                    this.mtd_select_region1();
                    this.supplier_expense.region_id = this.regions_id;
                    this.mtd_select_province1();
                    this.supplier_expense.province_id = this.provinces_id;
                    this.supplier_expense.district_id = this.districts_id;
                }
            }
        },
        mtd_select_country: function(){
            this.boo_country = this.supplier_expense.country_id != 173?true:false;
            this.supplier_expense.region_id = "";
            this.supplier_expense.district_id = "";
            this.supplier_expense.province_id = "";
        },
        mtd_select_region: function(){
            this.data_province_filter = [];
            this.data_provinces.forEach(element => {
                let prov =
                this.supplier_expense.region_id.length == 5
                    ? "0" + this.supplier_expense.region_id
                    : this.supplier_expense.region_id;
                if (element.region == prov) {
                    this.data_province_filter.push(element);
                }
            });
        },
        mtd_select_region1: function(){
            this.data_province_filter = [];
            this.data_provinces.forEach(element => {
                let prov =
                this.supplier_expense.region_id.length == 5
                    ? "0" + this.supplier_expense.region_id
                    : this.regions_id;
                if (element.region == prov) {
                    this.data_province_filter.push(element);
                }
            });
        },
        mtd_select_province: function () {
            this.data_disctrict_filter = [];
            this.data_disctrict.forEach((element) => {
                let prov =
                this.supplier_expense.province_id.length == 5
                    ? "0" + this.supplier_expense.province_id
                    : this.supplier_expense.province_id;
                if (element.province == prov) {
                this.data_disctrict_filter.push(element);
                }
            });
        },
        mtd_select_province1: function () {
            this.data_disctrict_filter = [];
            this.data_disctrict.forEach((element) => {
                let prov =
                this.supplier_expense.province_id.length == 5
                    ? "0" + this.supplier_expense.province_id
                    : this.provinces_id;
                if (element.province == prov) {
                this.data_disctrict_filter.push(element);
                }
            });
        },
        mtd_show: function(id) {
            this.get({
                url:
                this.$store.getters.get__url + "/" + this.prefix + "/" + id + "/show",
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                this.regions_id = parseInt(response.region);
                this.provinces_id = parseInt(response.province);
                this.districts_id = parseInt(response.district);
                this.supplier_expense = response.data;
                this.mtd_open_modal(true, "update");
            })
            .catch((errors) => { });
        },
        mtd_action: function() {
            if(this.supplier_expense.document.length>0){
                if(this.supplier_expense.email.length>0){
                    this.post({
                        url:
                        this.$store.getters.get__url +
                        "/" +
                        this.prefix +
                        "/" +
                        this.modal.action,
                        token: this.$store.getters.get__token,
                        params: this.supplier_expense,
                    })
                    .then((response) => {
                        let color = "success";
                        let message = "";
                        if (this.modal.action == "store") {
                            if (this.data.length == 0) {
                            this.mtd_getdata();
                            } else {
                            this.data.push(response[0]);
                            }
                            message = "REGISTRADO CORRECTAMENTE";
                        } else {
                            this.data.forEach((element) => {
                            if (element.Id == response[0].Id) {
                                element.Documento = response[0].document;
                                element.Nombre = response[0].name;
                            }
                            message = "EDITADO CORRECTAMENTE";
                            });
                        }
                        this.modal = {
                            action: "",
                            title: "",
                            modal_form: false,
                            document: "",
                            name: "",
                            business_name: "",
                            phone: "",
                            email: "",
                            address: ""
                        };
                        bus.$emit("alert", {
                            color: color,
                            message: message,
                        });
                    })
                    .catch((errors) => {
                        bus.$emit("alert", {
                            color: "danger",
                            message: errors,
                        });
                    });
                }else{
                    bus.$emit("alert", {
                        color: "danger", message: "Favor, Digite Correo Electronico.",
                    });
                }
            }else{
                bus.$emit("alert", {
                    color: "danger", message: "Favor, Digite Documento.",
                });
            }
        },
        mtd_commit: function () {
            this.post({
                url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
                token: this.$store.getters.get__token,
                params: {
                id: this.modal_delete.item.Id,
                },
            })
            .then((response) => {
                let temp = [];
                this.data.forEach((element) => {
                    if (element.Id != response) {
                    temp.push(element);
                    }
                });
                this.data = temp;
                this.modal_delete = {
                    boo: false,
                    item: [],
                };
                bus.$emit("alert", {
                    color: "success",
                    message: "ELIMINADO CORRECTAMENTE",
                });
            })
            .catch((errors) => {
                // this.errorsBackend = errors;
                // this.$emit("error", this.event);
            });
        },
        mtd_search_document: function(){
            this.get({
                url: this.$store.getters.get__url+"/novoapi/"+this.supplier_expense.document,
                token: this.$store.getters.get__token
            })
            .then((response)=>{
                if (response.boo == 0) {
                    bus.$emit("alert", {
                    color: "success",
                    message: "El Documento se encontró en la base de datos",
                    });
                    this.supplier_expense.name = response.razon_social;
                    this.boo_novo = true;
                } else if (response.boo == 1) {
                    bus.$emit("alert", {
                    color: "warning",
                    message: "DNI no existe, digite nuevamente el dni",
                    });
                    this.supplier_expense.name = "";
                    this.boo_novo = true;
                } else if (response.boo == 3) {
                    bus.$emit("alert", {
                    color: "danger",
                    message: "Ha ocurrido un problema, ingrese otra consulta  ",
                    });
                    this.supplier_expense.name = "";
                    this.boo_novo = true;
                } else {
                    bus.$emit("alert", {
                    color: "success",
                    message: "El DNI se encontró con la api",
                    });
                    this.supplier_expense.name = response.razon_social;
                    this.boo_novo = false;
                }
            })
            .catch((errors)=>{
                // this.errorsBackend = errors;
                // this.$emit("error", this.event);
            });

        },
        mtd_delete: function (item) {
            this.modal_delete.boo = true;
            this.modal_delete.item = item;
        },
        close_delete: function () {
            this.modal_delete.boo = false;
        },
    }
}
</script>

<style lang="scss" scoped>

</style>